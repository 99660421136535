<!-- 移动端确认定制信息 -->
<template>
  <div class="dialog-main">
    <div class="dialog-content">
      <div class="content-title">请确认您要选择的款式和定制内容</div>
      <img
        :src="`https://datacenter.53site.com/Werewolf/frame/${selectData.avatar_frame_id}_player.png`"
        class="select-img"
      />
      <div class="select-img-name">{{selectData.name}}</div>
      <div class="note-content">{{selectData.content}}</div>
      <div class="operaBtn confirm-btn" @click="clickConfrimBtn">确定</div>
      <div class="operaBtn cancel-btn" @click="clickCancelBtn">取消</div>
    </div>
  </div>
</template>

<script>
import { getParam } from "./../../utils/utils";
import { selectFrame } from "./../../apis/officialWeb";
export default {
  props: ["selectData"],
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    clickConfrimBtn: function() {
      let aliTradeNo = getParam("out_trade_no");
      let userNo = aliTradeNo.split('-')[2];
      //发送定制信息
      let sendData = {
        userNo: userNo,
        aliTradeNo: aliTradeNo,
        periodId: this.selectData.periodId,
        note: this.selectData.content
      };
      selectFrame(sendData).then(res => {
        // console.log(res);
        if (res.code == 1) {
          alert(
            "小狼已经收到您的定制信息啦！正在全速帮您制作至尊专属头像框 。"
          );
        } else {
          alert("定制内容提交失败，请联系客服提交。");
        }
        this.$parent.showMOBConfirmDia = false;
        this.$parent.$parent.MOBChooseItem = false;
        window.location.href = window.location.href.split("?")[0];
      });
      // console.log("sendData=>", sendData);
    },
    clickCancelBtn: function() {
      // console.log("点击了取消按钮,返回上一层");
      this.$parent.showMOBConfirmDia = false;
    }
  }
};
</script>
<style scoped lang='scss'>
@import "../../style/main.scss";
.dialog-main {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.dialog-content {
  width: 90vw;
  height: 123.7vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include imgbg(imgUrl("mob_c_dialog_bg.png"));
}
.content-title {
  width: 100%;
  text-align: center;
  font-size: 4vw;
  color: white;
  line-height: 4vw;
  position: absolute;
  margin-top: 7.1vw;
}
.select-img {
  width: 32.9vw;
  height: 32.4vw;
  margin-top: 18.8vw;
  margin-left: 28.6vw;
}
.select-img-name {
  width: 100%;
  text-align: center;
  font-size: 5.1vw;
  color: #ffbc55;
  line-height: 5.1vw;
  margin-top: 4.2vw;
}
.note-content {
  width: 100%;
  text-align: center;
  font-size: 10.1vw;
  color: #ffbc55;
  line-height: 10.1vw;
  margin-top: 10.1vw;
}
.operaBtn {
  width: 78.9vw;
  height: 12.1vw;
  border-radius: 2.4vw;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  font-size: 4.2vw;
  margin-left: 5.6vw;
}
.confirm-btn {
  margin-top: 10.8vw;
  background-color: #ffbc55;
  color: #825414;
}
.cancel-btn {
  margin-top: 3.2vw;
  background-color: #b877ec;
  color: #45007c;
}
</style>